import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import dpr from "@/common/dpr";
import countTo from 'vue3-count-to';
// import "default-passive-events"
import 'element-plus/dist/index.css'
// dpr.init();
let app = createApp(App);
app.use(router)
app.use(countTo)
app.mount('#app')
