import 'element-plus/es/components/message/style/css'
import {ElMessage } from "element-plus";

export default {
    success(text) {
        ElMessage.success(text);
    },
    error(text) {
        ElMessage.error(text);
    },
    info(text) {
        ElMessage.info(text);
    },
    warning(text) {
        ElMessage.warning(text);
    },
    message(option) {
        ElMessage(option);
    }
}