import { cache, cacheKeys } from "@/store";

const _convert = (source, key = "id") => {
  let target = {};
  Object.values(source).forEach((d) => (target[d[key]] = d));
  return target;
};

export const levelType = {
  province: { level: 1, levelName: "省", isOrg: true, isProvince: true },
  city: { level: 2, levelName: "市", isOrg: true, isCity: true },
  area: { level: 3, levelName: "区", isOrg: true, isArea: true },
  place: { level: 4, levelName: "所", isOrg: true, isPlace: true },
  street: {
    level: 5,
    levelName: "街道",
    isOrg: true,
    isStreet: true,
    isLowestOrg: true,
  },
  company: { level: 6, levelName: "单位", isOrg: false, isCompany: true },
  dangkou: {
    level: 7,
    levelName: "档口",
    isOrg: false,
    isDangkou: true,
    isLeaf: true,
  },
};
export const levelTypeEnum = _convert(levelType, "level");

export const accountType = {
  supervise: {
    id: 1,
    name: "监管账号",
    isSupervise: true,
    eq: (type) => type === accountType.supervise.id,
  },
  company: {
    id: 2,
    name: "单位账号",
    isCompany: true,
    eq: (type) => type === accountType.company.id,
  },
  setup: {
    id: 4,
    name: "集成商账号",
    isSetup: true,
    eq: (type) => type === accountType.setup.id,
  },
};
export const accountTypeEnum = _convert(accountType);

export const schoolType2 = {
  normal: { id: 1, name: "一般单位" },
  group: { id: 2, name: "集体用餐单位" },
};
export const schoolType2Enum = _convert(schoolType2);

export const videoType = {
  wpt: { id: 0, name: "无平台", iswpt: true },
  sl: { id: 1, name: "拾联", isSl: true },
  tdwy: { id: 2, name: "天地伟业", isTdwy: true },
  tykj: { id: 3, name: "天翼看家", isTykj: true },
  zdxf: { id: 4, name: "中电兴发", isZdxf: true },
  txsp: { id: 5, name: "腾讯视频", isTxsp: true },
  hksp_7: { id: 7, name: "视频中台7", isHksp_7: true },
  hksp_8: { id: 8, name: "视频中台8", isHksp_8: true },
  newTdwy: { id: 10, name: "新天地伟业", isNewTdwy: true },
  qly: { id: 11, name: "千里眼视频中台", isQly: true },
};

export const videoTypeEnum = _convert(videoType);

export const videoAreaType = [
  { id: 0, name: "分类列表" },
  { id: 1, name: "后厨" },
  { id: 2, name: "就餐区域" },
  { id: 3, name: "便利店" },
  { id: 4, name: "基地" },
];

export const workerType = {
  glry: { id: 1, name: "食堂管理人员" },
  hcry: { id: 2, name: "食堂后厨人员" },
  ctjl: { id: 3, name: "餐厅经理" },
  dtjl: { id: 4, name: "大堂经理" },
  zc: { id: 5, name: "主厨" },
  bc: { id: 6, name: "帮厨" },
};
export const workerTypeEnum = _convert(workerType);

export const validType = {
  normal: { id: 1, name: "未过期", textColor: "var(--app-status-color-ok)" },
  invalid: {
    id: 2,
    name: "已过期",
    textColor: "var(--app-status-color-failed)",
  },
  soon: {
    id: 3,
    name: "即将过期",
    textColor: "var(--app-status-color-warning)",
  },
};
export const validTypeEnum = _convert(validType);

export const gender = {
  male: { id: 1, name: "男" },
  female: { id: 2, name: "女" },
};
export const genderEnum = _convert(gender);

export const onLineStatus = {
  online: { id: 1, name: "在线", textColor: "var(--app-status-color-ok)" },
  offline: { id: 0, name: "离线", textColor: "var(--app-status-color-failed)" },
};
export const onLineStatusEnum = _convert(onLineStatus);

export const enableStatus = {
  disabled: {
    id: 0,
    name: "已禁用",
    textColor: "var(--app-status-color-failed)",
  },
  enabled: { id: 1, name: "正常", textColor: "var(--app-status-color-ok)" },
};
export const enableStatusEnum = _convert(enableStatus);

export const exStatus = {
  normal: { id: 0, name: "正常", textColor: "var(--app-status-color-ok)" },
  exception: {
    id: 1,
    name: "异常",
    textColor: "var(--app-status-color-failed)",
  },
};
export const exStatusEnum = _convert(exStatus);

export const infoEntryStatus = {
  normal: { id: 1, name: "已录入", textColor: "var(--app-status-color-ok)" },
  exception: {
    id: 2,
    name: "未录入",
    textColor: "var(--app-status-color-failed)",
  },
};
export const infoEntryStatusEnum = _convert(infoEntryStatus);

export const foodInType = {
  gc: { id: 1, name: "国产食品" },
  jk: { id: 2, name: "进口食品" },
};
export const foodInTypeEnum = _convert(foodInType);

export const disinfection = {
  gc: { id: 1, name: "物理" },
  jk: { id: 2, name: "化学" },
};
export const disinfectionEnum = _convert(disinfection);

export const mealTimes = {
  morning: { id: 1, name: "早" },
  noon: { id: 2, name: "中" },
  night: { id: 3, name: "晚" },
};
export const mealTimesEnum = _convert(mealTimes);

export const iotType = {
  dsb: { id: 1, name: '挡鼠板' },
  bx: { id: 2, name: '冰箱' },
  xdg: { id: 3, name: '消毒柜' },
}
export const iotTypeEnum = _convert(iotType);

export const iotStatus = {
  qy: { id: 1, name: '启用' },
  wqy: { id: 0, name: '未启用' },
}

export const iotStatusEnum = _convert(iotStatus);

export const foodIngredientsUnitEnum = cache.get(cacheKeys.foodIngredientsUnit);

export const orgConvert = (node) => {
  let orgModel = {};
  orgModel.id = node.did || node.id;
  orgModel.pid = node.dpid || node.pid;
  orgModel.name = node.dname || node.name;
  orgModel.isOnline = node.isOnline;
  orgModel.onlineRate = node.onlineRate;
  orgModel.videoType = node.videoType;
  orgModel.schoolType = node.schoolType;
  orgModel.children = node.children;
  orgModel.matched = node.matched;
  orgModel = { ...orgModel, ...levelTypeEnum[node.dlevel || node.levelType] };
  orgModel.hasChildren = !orgModel.isStreet;
  return orgModel;
};

export const orgWhere = (org) => {
  let tmp = {};
  if (org.isOrg) {
    //tmp.parentId = org.id;
    if (org.isProvince) {
      tmp.province = org.id;
    }
    if (org.isCity) {
      tmp.city = org.id;
    }
    if (org.isArea) {
      tmp.area = org.id;
    }
    if (org.isPlace) {
      tmp.place = org.id;
    }
    if (org.isStreet) {
      tmp.street = org.id;
    }
  } else if (org.isCompany || org.isDangkou) {
    tmp.schoolId = org.id;
    tmp.enterpriseId = org.id;
  }
  return tmp;
};

export const orgWhereAll = (org) => {
  let tmp = {};
  tmp.province = org.matched[0] || undefined;
  tmp.city = org.matched[1] || undefined;
  tmp.area = org.matched[2] || undefined;
  tmp.place = org.matched[3] || undefined;
  tmp.street = org.matched[4] || undefined;
  return tmp;
};

export const accountStatus = {
  dsh: { id: 0, name: "待审核" },
  ysh: { id: 1, name: "已审核" },
  shwtg: { id: 2, name: "审核未通过" },
};

export const morningCheckStatus = {
  normal: { id: 0, name: "正常" },
  exception: { id: 1, name: "异常" },
};

export const fuTianSchoolIdIds = [
  "440304",
  "59bbce671478458b94b9e081d3fa5f96",
  "63b7398f07c84365ba23f93dcc9da45d",
  "c6d00126e5494f9ab3a9ae891dcd5b4c",
  '4c9e953c197a45689f29e9dae40c0aae',
  'decf18023ce94690921711ecd99b43c7',
  'd0d35a41c6094387b2bd40a0f3c62926',
  '5d0333fdd7f24f7bb44eb6fca89208cb',
  'de65753de5934bcebdb71ea391b1c157',
  '9161c102047e4265a45ea6bd511de192',
  '9e22e8b8d6474cb3b079dfa52e035944',
  '85578b6e918b45e6892f9d8c50d205c3',
];
