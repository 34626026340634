import util from "@/common/util";
import { createStore } from 'vuex';

const _cacheKeys = {
    org: { key: 'org', defVal: {}, storage: sessionStorage },
    user: { key: 'user', defVal: {}, storage: sessionStorage },
    theme: { key: 'theme', defVal: 'default', storage: localStorage },
    loginForm: { key: 'loginForm', defVal: {}, storage: localStorage },
    clientId: { key: 'clientId', defVal: util.uuid(), storage: localStorage },
    orgTreeCache: { key: 'orgTreeCache', defVal: [], storage: sessionStorage },
    footfallReal: { key: 'footfallReal', defVal: [], storage: sessionStorage },
    timestamp: { key: 'timestamp', defVal: [], storage: sessionStorage },
    showRawPic: { key: 'showRawPic', defVal: false, storage: sessionStorage },
    companyInfo: { key: 'companyInfo', defVal: {}, storage: sessionStorage },
    errorTime: { key: 'errorTime', defVal: null, storage: localStorage },
    onlineInspection: { key: 'onlineInspection', defVal: null, storage: sessionStorage }, // 线上巡查（前端假数据）
    onOffLine: { key: 'onOffLine', defVal: null, storage: sessionStorage }, // 线上巡查（前端假数据）

    //枚举
    newsType: { key: 'newsType', defVal: {}, storage: sessionStorage },
    schoolType: { key: 'schoolType', defVal: {}, storage: sessionStorage },
    cameraType: { key: 'cameraType', defVal: {}, storage: sessionStorage },
    ledgerType: { key: 'ledgerType', defVal: {}, storage: sessionStorage },
    nvrFactory: { key: 'nvrFactory', defVal: {}, storage: sessionStorage },
    aiDeviceType: { key: 'aiDeviceType', defVal: {}, storage: sessionStorage },
    cameraFactory: { key: 'cameraFactory', defVal: {}, storage: sessionStorage },
    aiMessageType: { key: 'aiMessageType', defVal: {}, storage: sessionStorage },
    iotDeviceType: { key: 'iotDeviceType', defVal: {}, storage: sessionStorage },
    minspDeviceType: { key: 'minspDeviceType', defVal: {}, storage: sessionStorage },
    foodIngredientsUnit: { key: 'foodIngredientsUnit', defVal: {}, storage: sessionStorage },
    onlineCheckResultType: { key: 'onlineCheckResultType', defVal: {}, storage: sessionStorage },
    physicalConditionType: { key: 'physicalConditionType', defVal: {}, storage: sessionStorage },
    deviceType: { key: 'deviceType', defVal: {}, storage: sessionStorage },
    sceneType: { key: 'sceneType', defVal: {}, storage: sessionStorage },
};

const _storageApi = {
    prefix: 'sct',
    get(key) {
        if (!key.storage) {
            return this.set(key, key.defVal);
        }
        let val = key.storage.getItem(_storageApi.prefix + '.' + key.key);
        if (val) {
            return JSON.parse(val);
        }
        return this.set(key, key.defVal);
    },
    set(key, val) {
        if (!key.storage) {
            return val;
        }
        if (val === undefined || val === null) {
            val = key.defVal;
        }
        if (val !== undefined && val !== null) {
            key.storage.setItem(_storageApi.prefix + '.' + key.key, JSON.stringify(val));
        }
        return val;
    },
    clear(storage) {
        storage.clear();
    }
}
const init = () => {
    const state = {};
    const mutations = {};
    for (const key in _cacheKeys) {
        const keyVal = _cacheKeys[key];
        state[key] = _storageApi.get(keyVal);
        mutations[key] = (state, val) => state[key] = _storageApi.set(keyVal, val);
    }
    return { strict: true, state, mutations };
}

const store = createStore(init())

const _cacheApi = {
    set(key, val) {
        store.commit(key.key, val);
    },
    get(key) {
        return store.state[key.key];
    },
    raw(key) {
        return _storageApi.get(key);
    },
    rawSet(key, val) {
        return _storageApi.set(key, val);
    },
    del(key) {
        store.commit(key.key);
    },
    cleanSession() {
        _storageApi.clear(sessionStorage);
    },
    cleanLocal() {
        _storageApi.clear(localStorage);
    }
}

export const storage = _storageApi;
export const cache = _cacheApi;
export const cacheKeys = _cacheKeys;
export default store;