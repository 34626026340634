import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { computed } from "vue";
import { cache, cacheKeys } from "./store";
import { useRoute } from "vue-router";
export default {
  setup(__props) {
    const route = useRoute();
    const theme = computed(() => cache.get(cacheKeys.theme));
    const currentRouteClassName = computed(() => route.path.replace(/^\//, '').replace(/\//g, '-'));
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      const _component_el_config_provider = _resolveComponent("el-config-provider");

      return _openBlock(), _createBlock(_component_el_config_provider, {
        locale: _unref(zhCn)
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass(['sct-app', _unref(theme), _unref(currentRouteClassName)])
        }, [_createVNode(_component_router_view)], 2)]),
        _: 1
      }, 8, ["locale"]);
    };
  }

};