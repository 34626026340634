import 'element-plus/es/components/message-box/style/css'
import {ElMessageBox} from "element-plus";

export default {
    prompt(text, title, option) {
        const {success, error} = option;
        ElMessageBox.prompt(text, title, option).then(success).catch(error);
    },
    confirm(text, title, option) {
        const {success, error} = option;
        ElMessageBox.confirm(text, title, option).then(success).catch(error);
    }
}