import message from "@/common/message";
import messageBox from "@/common/messageBox";


export default {
  create: {
    text: "新增",
    isFunc: true,
    type: "success",
    isCreate: true,
    isShow: () => true,
  },
  export: {
    text: "导出(最多导出10000条)",
    isFunc: true,
    type: "info",
    isExport: true,
    isShow: () => true,
    handler: ({ api, search }, funcSuccessfulCallback) => {
      api.export(search, funcSuccessfulCallback);
    },
  },
  exportList: {
    text: "导出列表",
    isFunc: true,
    type: "info",
    isExport: true,
    isShow: () => true,
    handler: ({ api, search }, funcSuccessfulCallback) => {
      api.exportList(search, funcSuccessfulCallback);
    },
  },

  deleteBatch: {
    text: "批量删除",
    isFunc: true,
    type: "danger",
    isBatch: true,
    isDeleteBatch: true,
    isShow: ({ rows }) => rows.length > 0,
    handler: ({ api, rows, success }, funcSuccessfulCallback) => {
      messageBox.confirm(
        `此操作将永久删除选中的 ${rows.length} 条记录，是否继续？`,
        "批量删除确认",
        {
          confirmButtonText: "确认删除",
          cancelButtonText: "取消",
          type: "error",
          success: () => {
            const doDelete = async function () {
              for (const row of rows) {
                await api.del(row.id);
              }
              message.success("批量删除成功");
              success();
              funcSuccessfulCallback();
            };
            doDelete();
          },

        }
      );
    },
  },

  download: {
    text: "查看（下载）",
    isRow: true,
    type: "info",
    isShow: () => true,
    handler: ({ api, row }) => api.exportId(row.id),
  },
  modify: {
    text: "编辑",
    isRow: true,
    type: "primary",
    isModify: true,
    isShow: () => true,
  },
  delete: {
    text: "删除",
    isRow: true,
    type: "danger",
    isDelete: true,
    isShow: () => true,
    handler: ({ api, row, success }, rowKey) => {
      messageBox.confirm(`此操作将永久删除此记录，是否继续？`, "删除确认", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "error",
        success: () => {
          api.del(row[rowKey]).then(() => {
            message.success("删除成功");
            success();
          });
        },
      });
    },
  },
  detail: {
    text: "详情",
    isRow: true,
    type: "warning",
    isDetail: true,
    isShow: () => true,
  },
  upgradation: {
    text: "升级",
    isRow: true,
    type: "success",
    isShow: () => true,
    handler: () => {
      messageBox.confirm(`是否升级到最新版本？`, "升级确认", {
        confirmButtonText: "确认升级",
        cancelButtonText: "取消",
        type: "warning",
        success: () => {
          message.success("升级成功");
        },
      });
    },
  },
};
