import html2canvas from "html2canvas";
import { ElMessage } from "element-plus";
import coordtransform from "coordtransform";
const SOCKET_URL = "ws://sct.skyense.cn:50001/socket.io";
const appointTimestamp = new Date("2022-06-13 11:00:00").getTime();
function fullScreen() {
  let element = document.documentElement;
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  }
}
function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}

function dataURLToBlob(dataurl) {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
function formatNum(num) {
  num = Number(num);
  let newNum = 0;
  newNum = num < 10 ? '0' + num : num;
  return newNum
}

export default {
  saveToImage(divText, imgText) {
    let canvasID = document.getElementById(divText);
    let a = document.createElement("a");
    html2canvas(canvasID).then((canvas) => {
      let dom = document.body.appendChild(canvas);
      dom.style.display = "none";
      a.style.display = "none";
      document.body.removeChild(dom);
      let blob = dataURLToBlob(dom.toDataURL("image/png"));
      a.setAttribute("href", URL.createObjectURL(blob));
      a.setAttribute("download", imgText + ".png");
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(blob);
      document.body.removeChild(a);
    });
  },
  uuid: function () {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  },
  merge(dest1, dest2) {
    if (!dest1) {
      return;
    }
    for (const tmpKey in dest2) {
      dest2[tmpKey] = dest1[tmpKey];
    }
  },
  fileUrl(file) {
    if (window.createObjectURL !== undefined) {
      // basic
      return window.createObjectURL(file);
    } else if (window.URL !== undefined) {
      // mozilla(firefox)
      return window.URL.createObjectURL(file);
    } else if (window.webkitURL !== undefined) {
      // webkit or chrome
      return window.webkitURL.createObjectURL(file);
    }
    return null;
  },
  download(response) {
    if (!response) return;
    let fileName = response.headers["content-disposition"].replace(
      "attachment;filename*=utf-8'zh_cn'",
      ""
    );
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const downloadElement = document.createElement("a");
    downloadElement.href = url;
    downloadElement.download = decodeURIComponent(fileName);
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.bodyNaNpxoveChild(downloadElement);
    window.URL.revokeObjectURL(url);
  },
  toQuery(data) {
    if (data) {
      let keys = Object.keys(data);
      return keys
        .filter((k) => data[k])
        .map((k) => k + "=" + data[k])
        .join("&");
    }
    return "";
  },
  getOs() {
    let sUserAgent = navigator.userAgent;
    let isWin =
      navigator.platform == "Win32" || navigator.platform == "Windows";
    let isMac =
      navigator.platform == "Mac68K" ||
      navigator.platform == "MacPPC" ||
      navigator.platform == "Macintosh" ||
      navigator.platform == "MacIntel";
    if (isMac) return "Mac";
    let isUnix = navigator.platform == "X11" && !isWin && !isMac;
    if (isUnix) return "Unix";
    let isLinux = String(navigator.platform).indexOf("Linux") > -1;
    if (isLinux) return "Linux";
    if (isWin) {
      let isWin2K =
        sUserAgent.indexOf("Windows NT 5.0") > -1 ||
        sUserAgent.indexOf("Windows 2000") > -1;
      if (isWin2K) return "Win2000";
      let isWinXP =
        sUserAgent.indexOf("Windows NT 5.1") > -1 ||
        sUserAgent.indexOf("Windows XP") > -1;
      if (isWinXP) return "WinXP";
      let isWin2003 =
        sUserAgent.indexOf("Windows NT 5.2") > -1 ||
        sUserAgent.indexOf("Windows 2003") > -1;
      if (isWin2003) return "Win2003";
      let isWinVista =
        sUserAgent.indexOf("Windows NT 6.0") > -1 ||
        sUserAgent.indexOf("Windows Vista") > -1;
      if (isWinVista) return "WinVista";
      let isWin7 =
        sUserAgent.indexOf("Windows NT 6.1") > -1 ||
        sUserAgent.indexOf("Windows 7") > -1;
      if (isWin7) return "Win7";
      let isWin10 = sUserAgent.indexOf("Windows NT 10.0") > -1;
      if (isWin10) return "Win10";
    }
    return "other";
  },
  isIframe() {
    if (self.frameElement && self.frameElement.tagName === "IFRAME") {
      return self.frameElement.getAttribute("name") === "sct-iframe";
    }
    return false;
  },
  socketInit(message, u) {
    let ws = null,
      interval = null;
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
    if (ws) {
      ws.close();
      ws = null;
    }
    let address = SOCKET_URL;
    let params = "";
    params += "?t=" + new Date().getTime();
    params += "&u=" + u;
    ws = new WebSocket(address + params);
    ws.onopen = () => {
      ElMessage({
        type: "success",
        message: "客流统计实时显示已开启",
        duration: 2000,
        showClose: true,
      });
      interval = setInterval(() => {
        ws.send("ping");
      }, 10 * 1000);
    };
    ws.onmessage = (event) => {
      let msg = event.data;
      // console.log(msg);
      if (!msg) {
        return;
      }
      if (msg === "pong") {
        return;
      }
      message(JSON.parse(msg));
    };
    ws.onclose = () => {
      setTimeout(() => {
        console.log("socket closed ! reconnecting ...");
        this.socketInit(message, u);
      }, 10 * 1000);
    };
    ws.onerror = () => {};
  },

  toggleScreen() {
    let isFull = !!(
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement
    );
    if (isFull === false) {
      fullScreen();
    } else {
      exitFullscreen();
    }
  },

  setTreeData(jsonData) {
    const cloneData = JSON.parse(JSON.stringify(jsonData));
    return cloneData.filter((father) => {
      const branchArr = cloneData.filter((child) => father.id === child.pid);
      branchArr.length > 0 ? (father.children = branchArr) : "";
      return father.pid == String(100000);
    });
  },

  formatTimestamp(createTime, longitude, latitude) {
    let newCreateTime = new Date(createTime).getTime();
    let lng = null;
    let lat = null;
    if (newCreateTime >= appointTimestamp) {
      lng = longitude;
      lat = latitude;
    } else {
      let LngLat = coordtransform.gcj02towgs84(longitude, latitude);
      lng = LngLat[0];
      lat = LngLat[1];
    }

    return [lng, lat];
  },
  getAge(identityCard) {
    let len = (identityCard + "").length;
    if (len == 0) {
      return "";
    } else {
      if (len != 15 && len != 18) {
        return "";
      }
    }
    let strBirthday = "";
    if (len == 18) {
      strBirthday =
        identityCard.substr(6, 4) +
        "/" +
        identityCard.substr(10, 2) +
        "/" +
        identityCard.substr(12, 2);
    }
    if (len == 15) {
      strBirthday =
        "19" +
        identityCard.substr(6, 2) +
        "/" +
        identityCard.substr(8, 2) +
        "/" +
        identityCard.substr(10, 2);
    }

    let birthDate = new Date(strBirthday);
    let nowDateTime = new Date();
    let age = nowDateTime.getFullYear() - birthDate.getFullYear();
    if (age < 0) {
      age = 20;
    }
    if (
      nowDateTime.getMonth() < birthDate.getMonth() ||
      (nowDateTime.getMonth() == birthDate.getMonth() &&
        nowDateTime.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  },
  convert(source) {
    let keys = Object.keys(source);
    let target = {};
    keys.forEach((d) => {
      target[d] = { id: d, name: source[d] };
    });
    return target;
  },
  getObjectKey(object, key) {
    let arr = Object.values(object);
    let values = [];
    if (arr && arr.length > 0) {
      arr.forEach((item) => {
        values.push(item[key]);
      });
      return values;
    }
  },
  getCurrentMonth() {
    var end = new Date();
    var year = end.getFullYear();
    var month = end.getMonth() + 1; //0-11表示1-12月
    var day = end.getDate();
    var dateObj = {};
    dateObj.end = year + "-" + formatNum(month) + "-" + formatNum(day);
    var endMonthDay = new Date(year, month, 0).getDate(); //当前月的总天数
    if (month - 1 <= 0) {
      //如果是1月，年数往前推一年<br>
      dateObj.start = year - 1 + "-" + 12 + "-" + day;
    } else {
      var startMonthDay = new Date(year, parseInt(month) - 1, 0).getDate();
      if (startMonthDay < day) {
        //1个月前所在月的总天数小于现在的天日期
        if (day < endMonthDay) {
          //当前天日期小于当前月总天数
          dateObj.start =
            year +
            "-" +
            formatNum(month-1) +
            "-" +
            formatNum((startMonthDay - (endMonthDay - day)))
            ;
        } else {
          dateObj.start = year + "-" + formatNum(month-1) + "-" +formatNum(startMonthDay) ;
        }
      } else {
        dateObj.start = year + "-" + formatNum(month-1) + "-" + formatNum(day);
      }
    }
    return dateObj;
  },


};
