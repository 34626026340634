import {createRouter, createWebHashHistory} from 'vue-router'
import {cache, cacheKeys} from "../store";
import {load as appRoutes} from "./app";
import {levelType} from "@/consts"
import util from "@/common/util";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            redirect: '/app',
        },
        ...appRoutes(),
        {
            path: '/login',
            meta: {title: '登录'},
            component: () => import('../views/base/LoginView'),
        },
        {
            path: '/modifyPwd',
            meta: {title: '修改密码'},
            component: () => import('../views/base/FocusModifyPwd'),
        },
        {
            path: '/:pathMatch(.*)*',
            meta: {title: '404'},
            component: () => import('../views/base/NotFoundView'),
        },
    ],
})

const doInitTitle = ({matched}) => {
    document.title = matched.map(d => d.meta.title).filter(d => d).join('-');
}

const doInitMeta = ({matched, meta}) => {
    let sidebar = {
        reverse: () => false,
        isShow: () => true,
        org: {level: levelType.dangkou, isShow: () => true},
        module: {isShow: () => true},
    };
    let header = {
        isShow: () => true,
    };
    let navbar = {
        isShow: () => true,
    };
    let body = {
        ops: [],
        search: true,
        dateRange: false,
        description: '请选择组织或单位后查看',
        isShow: () => true,
    };
    matched.forEach(({meta}) => {
        {
            const tmp = meta.sidebar || sidebar;
            tmp.org = {...sidebar.org, ...tmp.org};
            tmp.module = {...sidebar.module, ...tmp.module};
            sidebar = {...sidebar, ...tmp};
        }//初始化侧边栏
        {
            header = {...header, ...meta.header};
        }//初始化头部
        {
            navbar = {...navbar, ...meta.navbar};
        }//初始化导航栏
        {
            body = {...body, ...meta.body};
        }//初始化内容
    });
    meta.header = header;
    meta.navbar = navbar;
    meta.sidebar = sidebar;
    meta.body = body;
    if ((!sidebar.isShow() || !sidebar.org.isShow()) && !util.isIframe()) {//不显示侧边栏或组织架构时清除已选择的组织单位
        cache.del(cacheKeys.org);
    }
}

router.beforeEach((to) => {
    const user = cache.get(cacheKeys.user);
    if (to.path === '/login') {
        if (user.id) {
            return {path: /*to.query.redirect || */'/'};
        }//已登录状态进入到登录页，跳转到首页
        return true;
    }//判断是否登录页
    if (!user.id) {
        cache.cleanSession();
        /*let redirectPath = to.fullPath;
        if (redirectPath === '/modifyPwd') {
            redirectPath = '/';
        }*/
        return {path: '/login'/*, query: {redirect: redirectPath}*/}
    }//目标为非登录页且未登录的情况，清除会话并跳转到登录页
    if (!user.hasModifyPwd && to.path !== '/modifyPwd') {
        return {path: '/modifyPwd'};
    }
    {
        const children = to.matched[to.matched.length - 1].children;
        if (children && children.length > 0) {
            return children[0];
        }
    }//自动重定向到第一个子页面
    doInitTitle(to);
    doInitMeta(to);
    return true;
})
export default router
